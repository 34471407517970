<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Input from "$lib/admin/components/Input.svelte";
    import Group from "$lib/admin/components/Group.svelte";
    import ToplistRow from "$lib/admin/components/blocks/helpers/ToplistRow.svelte";

    import { orgOperators } from "$lib/services/store";
    import type { Operator } from "@/src/types/components/operators/Operator";
    import Block from "../helpers/Block.svelte";

    export let props: { operatorId: number; toplistFocus: string, toplistPaymentMethodProvider: string } = {
        operatorId: 0,
        toplistFocus: "",
        toplistPaymentMethodProvider: ""
    };
    export let adminView = false;
    let operator: Operator | undefined;

    const operators: Operator[] = $orgOperators;
    const dispatch = createEventDispatcher();

    $: operator = operators.find((x) => x.id === props.operatorId);
</script>

{#if adminView}
    <Group colspan="4-8">
        <Input
            label="Operator"
            type="select"
            bind:value={props.operatorId}
            on:change={() => dispatch("keyup")}
        >
            <option value="">~not set~</option>
            {#each operators as operator}
                <option value={operator.id}
                    >{operator.name ?? "Couldn't find metadata"}</option
                >
            {/each}
        </Input>
    </Group>
    <Group colspan="4-8" addClass="form-item">
        <Input
            type="select"
            label="Toplist topic"
            on:change
            bind:value={props.toplistFocus}
        >
            <option value="default">Default</option>
            <option value="casino">Casino (Legacy)</option>
            <option value="casino-bonus">Casino Bonus</option>
            <option value="sport">Sports Bonus</option>
            <option value="freespins-bonus">Freespins Bonus</option>
            <option value="mindeposit">Min. deposit</option>
            <option value="slots">Slots</option>
            <option value="lotto">Lotto</option>
            <option value="streaming">Streaming</option>
            <option value="paymentmethod">Payment Method</option>
            <option value="new-casinos">New Casinos</option>
            <option value="no-account">No account</option>
            <option value="experience">Experience</option>
            <option value="fast-withdrawals">Fast Withdrawals</option>
        </Input>
        {#if props.toplistFocus === "casino"}
            <div></div>
            <div
                style="background-color: #ffaeae; border-radius: 8px; border: 1px solid ##f00; color: #941100; padding: 2px 10px; font-size: 15px;"
            >
                <span
                    >The "Casino" topic was updated to "Default". Please update
                    it. The topic "Casino" will be discontinued.</span
                >
            </div>
        {/if}
        {#if props.toplistFocus === "paymentmethod"}
            <Input
                label="↳ Payment Method Provider"
                placeholder="E.g: swish"
                bind:value={props.toplistPaymentMethodProvider}
            />
        {/if}
    </Group>
{:else}
    <Block blockClass="block-toplist v1 v0" {props}>
        <div class="toplist-holder">
            <div class={`toplist ${props.toplistFocus}`} class:show-full={true}>
                <ToplistRow {props} {operator} toplistPosition={1} />
            </div>
        </div>
    </Block>
{/if}
