<script>
  export let operators = [];
</script>

<div class="container">
  <div
    style="background-color: #f5f5f5; padding: 10px; margin: 10px 0; border-radius: 6px; opacity: 0.7"
  >
    <p style="margin: 0; padding: 0; color: red; font-size: 16px;">
      <strong>
        {operators.find((operator) => operator.id === props.operatorId)?.name}
        has no Experience Rounds available</strong
      >, this component will NOT render for this page.
      <br />
      <br />
      What you are seeing now is just a placeholder so you can see where the component
      would be placed.
    </p>
  </div>
</div>
