<script lang="ts">
  import { onMount } from "svelte";
  import Skeleton from "$lib/main/components/Skeleton.svelte";
  import Input from "../../Input.svelte";
  import {
    fetchTournaments,
    fetchLiveTournaments,
    fetchTournamentResults,
    fetchTournamentLeaderBoard,
    fetchFeatureTournaments,
  } from "$lib/services/tournaments";
  import type {
    Tournament,
    LeaderboardResponse,
    LeaderboardEntry,
  } from "@/src/types/tournaments";

  export let props: { tabs: any[] };
  export let adminView = false;

  interface Tab {
    title: string;
    selectedColumns: {
      key: string;
      displayName: string;
      booleanValue?: boolean | "any";
      hidden?: boolean;
    }[];
    endpoint?: string;
    additionalArgs?: { tournament_id?: number; tournamentIdList?: number };
    endpointSelected?: boolean;
  }

  let tabs: Tab[] = props.tabs || [
    {
      title: "Tournaments",
      selectedColumns: [],
      displayName: "",
      endpoint: "",
      additionalArgs: {},
      endpointSelected: false,
    },
  ];

  let activeTab = 0;
  let data: (Tournament | LeaderboardEntry | Record<string, any>)[] = [];
  let dataKeys: string[] = [];
  let skeletons = Array(10).fill({});
  let isLoading = true;
  let localAdditionalArgValue: number | null = null;

  onMount(async () => {
    if (tabs[activeTab].endpoint) {
      await fetchData();
    }
  });

  async function fetchData() {
    isLoading = true;
    data = [];
    try {
      const result = await getDataByEndpoint(
        tabs[activeTab].endpoint,
        tabs[activeTab].additionalArgs
      );
      if (Array.isArray(result)) {
        data = result as (Tournament | LeaderboardEntry)[];
      } else {
        data = (result as LeaderboardResponse).scoreboard;
      }
      dataKeys = Object.keys(data[0] || {});
      if (tabs[activeTab].selectedColumns.length === 0) {
        tabs[activeTab].selectedColumns = dataKeys
          .slice(0, 5)
          .map((key) => ({ key, displayName: key }));
      }
      tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.filter(
        (col) => dataKeys.includes(col.key)
      );
      props.tabs = [...tabs];
    } catch (error) {
      console.error("Error fetching tournaments:", error);
    } finally {
      isLoading = false;
      tabs[activeTab].endpointSelected = true;
      props.tabs = [...tabs];
    }
  }

  function handleCheckboxChange(key: string, event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      if (dataKeys.includes(key)) {
        tabs[activeTab].selectedColumns.push({ key, displayName: key });
      }
    } else {
      tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.filter(
        (col) => col.key !== key
      );
    }
    tabs = [...tabs];
    props.tabs = [...tabs];
  }

  function handleDisplayNameChange(key: string, newDisplayName: string) {
    tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.map(
      (col) => (col.key === key ? { ...col, displayName: newDisplayName } : col)
    );
    tabs = [...tabs];
    props.tabs = [...tabs];
  }

  function handleBooleanValueChange(key: string, value: boolean | "any") {
    tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.map(
      (col) => (col.key === key ? { ...col, booleanValue: value } : col)
    );
    tabs = [...tabs];
    props.tabs = [...tabs];
  }

  function handleHiddenChange(key: string, hidden: boolean) {
    tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.map(
      (col) => (col.key === key ? { ...col, hidden } : col)
    );
    tabs = [...tabs];
    props.tabs = [...tabs];
  }

  function handleEndpointChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    tabs[activeTab].endpoint = target.value;
    tabs[activeTab].additionalArgs = {};
    localAdditionalArgValue = null;
    tabs[activeTab].endpointSelected = false;
    tabs = [...tabs];
    props.tabs = [...tabs];
  }

  function enableEndpointChange() {
    tabs[activeTab].endpointSelected = false;
    tabs = [...tabs];
    props.tabs = [...tabs];
  }

  function handleAdditionalArgsChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = parseInt(target.value, 10);
    localAdditionalArgValue = isNaN(value) ? null : value;
    const key =
      tabs[activeTab].endpoint === "leaderboard"
        ? "tournament_id"
        : "tournamentIdList";
    tabs[activeTab].additionalArgs = {
      ...tabs[activeTab].additionalArgs,
      [key]: localAdditionalArgValue,
    };
    tabs = [...tabs];
    props.tabs = [...tabs];
  }

  function setActiveTab(index: number) {
    activeTab = index;
    const currentTab = tabs[activeTab];
    if (currentTab.endpoint === "leaderboard") {
      localAdditionalArgValue =
        currentTab.additionalArgs?.tournament_id || null;
    } else if (currentTab.endpoint === "featured") {
      localAdditionalArgValue =
        currentTab.additionalArgs?.tournamentIdList || null;
    } else {
      localAdditionalArgValue = null;
    }
    if (currentTab.endpointSelected) {
      fetchData();
    }
  }

  function addTab() {
    tabs = [
      ...tabs,
      {
        title: `Tab ${tabs.length + 1}`,
        selectedColumns: [],
        endpoint: "",
        additionalArgs: {},
        endpointSelected: false,
      },
    ];
    setActiveTab(tabs.length - 1);
    tabs = [...tabs];
    props.tabs = [...tabs];
  }

  function removeTab(index: number) {
    if (tabs.length > 1) {
      tabs = tabs.filter((_, i) => i !== index);
      setActiveTab(tabs.length - 1);
      tabs = [...tabs];
      props.tabs = [...tabs];
    }
  }

  function filterData(
    row: Tournament | LeaderboardEntry | Record<string, any>
  ) {
    for (const col of tabs[activeTab].selectedColumns) {
      if (
        col.key in row &&
        typeof row[col.key] === "boolean" &&
        col.booleanValue !== "any" &&
        col.booleanValue !== undefined
      ) {
        if (row[col.key] !== col.booleanValue) {
          return false;
        }
      }
    }
    return true;
  }

  async function getDataByEndpoint(
    endpoint?: string,
    additionalArgs?: { tournament_id?: number; tournamentIdList?: number }
  ) {
    switch (endpoint) {
      case "live":
        return await fetchLiveTournaments();
      case "results":
        return await fetchTournamentResults();
      case "leaderboard":
        if (additionalArgs?.tournament_id !== undefined) {
          return await fetchTournamentLeaderBoard(additionalArgs.tournament_id);
        }
        break;
      case "featured":
        if (additionalArgs?.tournamentIdList !== undefined) {
          return await fetchFeatureTournaments(additionalArgs.tournamentIdList);
        }
        break;
      default:
        return await fetchTournaments();
    }
  }

  $: props.tabs = tabs;
</script>

<div class={adminView ? "tab-container" : "tabs"}>
  {#each tabs as tab, index}
    {#if adminView}
      <div
        role="presentation"
        class={index === activeTab ? "tab-content active" : "tab-content"}
        on:click={() => setActiveTab(index)}
      >
        <Input type="text" bind:value={tab.title} disabled={!adminView} />
        {#if index > 0}
          <button
            class="btn-remove"
            on:click={(e) => {
              e.stopPropagation();
              removeTab(index);
            }}>✖</button
          >
        {/if}
      </div>
    {:else}
      <div
        role="presentation"
        class={index === activeTab ? "tab active" : "tab"}
        on:click={() => setActiveTab(index)}
      >
        {tab.title}
      </div>
    {/if}
  {/each}
  {#if adminView}
    <button class="tab-controls" on:click={addTab}>Add Tab</button>
  {/if}
</div>

{#if adminView}
  <div class="body-content">
    <label for="endpoint">Step 1: Select the endpoint:</label>
    {#if tabs[activeTab].endpointSelected}
      <div style="background-color: green;">
        <p>Saved endpoint: {tabs[activeTab].endpoint}</p>
        <button on:click={enableEndpointChange}>Change endpoint</button>
      </div>
    {:else}
      <select
        id="endpoint"
        bind:value={tabs[activeTab].endpoint}
        on:change={handleEndpointChange}
      >
        <option value="" disabled>Select an endpoint</option>
        <option value="schedule">Tournament Schedule</option>
        <option value="live">Live Tournaments</option>
        <option value="results">Tournament Results</option>
        <option value="leaderboard">Tournament Leaderboard</option>
        <option value="featured">Feature Tournaments</option>
      </select>
      {#if tabs[activeTab].endpoint === "leaderboard" || tabs[activeTab].endpoint === "featured"}
        <div>
          <label for="additionalArg"
            >{tabs[activeTab].endpoint === "leaderboard"
              ? "Tournament ID"
              : "Tournament ID List"}</label
          >
          <input
            type="number"
            id="additionalArg"
            bind:value={localAdditionalArgValue}
            on:input={handleAdditionalArgsChange}
          />
        </div>
      {/if}
      <button
        on:click={fetchData}
        disabled={(tabs[activeTab].endpoint === "leaderboard" ||
          tabs[activeTab].endpoint === "featured") &&
          !localAdditionalArgValue}
      >
        REQUEST
      </button>
    {/if}

    {#if dataKeys.length > 0 && tabs[activeTab].endpointSelected}
      Step 2: Select the columns:
      <div class="checkbox-list">
        {#each dataKeys as key}
          <div class="column-config">
            <label>
              <input
                type="checkbox"
                on:change={(e) => handleCheckboxChange(key, e)}
                checked={tabs[activeTab].selectedColumns.some(
                  (col) => col.key === key
                )}
              />
              {key}
            </label>
            {#if tabs[activeTab].selectedColumns.some((col) => col.key === key)}
              <div>
                <label for={key}>Rename Column:</label>
                <input
                  id={key}
                  type="text"
                  value={tabs[activeTab].selectedColumns.find(
                    (col) => col.key === key
                  )?.displayName || "fallback for display name"}
                  on:input={(e) =>
                    handleDisplayNameChange(key, e.currentTarget.value)}
                  placeholder="Display name"
                />
              </div>
              {#if typeof data[0]?.[key] === "boolean"}
                <select
                  on:change={(e) =>
                    handleBooleanValueChange(
                      key,
                      e.currentTarget.value === "true"
                        ? true
                        : e.currentTarget.value === "false"
                          ? false
                          : "any"
                    )}
                  value={tabs[activeTab].selectedColumns
                    .find((col) => col.key === key)
                    ?.booleanValue?.toString() || "any"}
                >
                  <option value="any">Any</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <label>
                  <input
                    type="checkbox"
                    on:change={(e) =>
                      handleHiddenChange(key, e.currentTarget.checked)}
                    checked={tabs[activeTab].selectedColumns.find(
                      (col) => col.key === key
                    )?.hidden || false}
                  />
                  Hide Column
                </label>
              {/if}
            {/if}
          </div>
        {/each}
      </div>
    {/if}
  </div>
{:else}
  <div class="content">
    <div class="table-content">
      {#if !isLoading && data.length > 0}
        <div
          class="row header"
          style={`grid-template-columns: repeat(${tabs[activeTab].selectedColumns.filter((col) => !col.hidden).length}, 1fr);`}
        >
          {#each tabs[activeTab].selectedColumns.filter((col) => !col.hidden) as column}
            <th>{column.displayName}</th>
          {/each}
        </div>
        {#each data.filter(filterData) as row}
          <div
            class="row"
            style={`grid-template-columns: repeat(${tabs[activeTab].selectedColumns.filter((col) => !col.hidden).length}, 1fr);`}
          >
            {#each tabs[activeTab].selectedColumns.filter((col) => !col.hidden) as column}
              <td>
                {#if column.key in row}
                  {#if typeof row[column.key] === "boolean"}
                    {row[column.key] ? "True" : "False"}
                  {:else}
                    {row[column.key] !== undefined ? row[column.key] : "N/A"}
                  {/if}
                {:else}
                  N/A
                {/if}
              </td>
            {/each}
          </div>
        {/each}
      {:else if isLoading}
        {#each skeletons as _, index}
          <Skeleton
            maxWidth="100%"
            height="20px"
            customStyles="margin-bottom: 10px;"
          />
        {/each}
      {:else}
        <div class="no-data">No data available</div>
      {/if}
    </div>
  </div>
{/if}

<style>
  .column-config {
    background: pink;
    padding: 10px;
  }
  .tabs {
    display: flex;
    margin-left: 18px;
  }
  .tab {
    width: 180px;
    padding: 8px 0;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    cursor: pointer;
  }
  .tab:last-child {
    border-radius: 0 7px 0 0;
  }
  .tab:first-child {
    border-radius: 7px 0 0 0;
  }
  .tab:only-child {
    border-radius: 7px 7px 0 0;
  }
  .tab.active {
    background-color: #3e3e3e;
    color: white;
    font-weight: bold;
    border: 1px solid transparent;
  }
  .tab-controls {
    display: flex;
    align-items: center;
  }

  .row {
    display: grid;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
  }
  .row.header {
    font-weight: bold;
    background-color: #f0f0f0;
  }
  .tab-content.active {
    background-color: var(--clr-pri);
  }
  .tab-container {
    display: flex;
    gap: 4px;
  }
  .tab-content {
    display: flex;
    background-color: var(--bg-clr);
    border: 1px solid var(--bg-clr);
    padding: 6px;
    border-radius: 10px;
  }
  .body-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    gap: 5px;
  }
  .checkbox-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
  }
  .btn-remove {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .table-content {
    width: 100%;
    gap: 8px;
    display: grid;
  }
  .content {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    padding: 20px 18px;
    background-color: #3e3e3e;
  }
  .no-data {
    color: white;
    text-align: center;
    padding: 20px;
  }

  @media (max-width: 991px) {
    .table-content {
      width: max-content;
      gap: 8px;
      display: grid;
    }
  }
</style>
