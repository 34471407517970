<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { project, orgOperators } from "$lib/services/store";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import SunEditorForm from "../../content/SunEditorForm.svelte";
  import { getBucketLink } from "$lib/common/helpers/image";

  export let props: any;
  export let adminView: boolean;

  let operators = $orgOperators as Operator[];
  const dispatch = createEventDispatcher();

  function addListItem() {
    props.listItems = [
      ...props.listItems,
      {
        operator: null,
        description: "",
      },
    ];
  }

  function removeListItem() {
    if (props.listItems.length > 0) {
      props.listItems = props.listItems.slice(0, -1);
    }
  }

  $: {
    if (props.listItems === undefined) {
      props.listItems = [];
    }
  }
</script>

{#if adminView}
  {#if props.listItems?.length > 0}
    {#each props.listItems as item, index (index)}
      <Group colspan="4-8">
        <Input
          label={"Operator" + " " + (index + 1)}
          type="select"
          addClass="h4"
          bind:value={item.operatorId}
          on:change={() => dispatch("keyup")}
        >
          <option value="">~not set~</option>
          {#each operators as operator}
            <option value={operator.id}
              >{operator.id} - {operator.name ??
                "Couldn't find metadata"}</option
            >
          {/each}
        </Input>
        <Input
          label="Title"
          type="text"
          required
          bind:value={item.title}
          on:keyup
        />
      </Group>
      <Group>
        <SunEditorForm bind:text={item.content} on:keyup />
      </Group>
    {/each}
  {/if}
  <div>
    {#if props.listItems?.length < 4}
      <Button
        addClass="primary"
        icon="plus-square"
        on:click={() => addListItem()}>Add item</Button
      >
    {/if}
    {#if props.listItems?.length > 3}
      <Button
        addClass="secondary"
        icon="minus-square"
        on:click={() => removeListItem()}>Remove last item</Button
      >
    {/if}
  </div>
{:else}
  <div
    class="container card-container"
    style={`grid-template-columns: repeat(${props.listItems.length}, 1fr);`}
  >
    {#each props.listItems as item, index (index)}
      {#if item.operatorId}
        {#each operators as operator (operator.id)}
          {#if operator.id === item.operatorId}
            <a
              href={`https://www.casinofeber.se/svenska-casinon/${operator?.name_lower}`}
              class="card"
              data-name={operator?.name}
              data-type="title"
              data-alias={`${index + 1} Highlight`}
              data-element="top-banner"
              data-operator={operator?.name_lower}
              data-toplist-pos={index + 1}
            >
              <div class="card-content">
                <div
                  style={`background-color: rgba(${
                    operator?.visuals?.[$project.key][$project.settings.market]
                      .primary_color
                  }); background-image: url("${getBucketLink()}operators/${operator?.logo?.[$project.key]}"); background-size: 90%;
                    background-repeat: no-repeat;
                    background-position: center;`}
                  title={operator?.name}
                  class="logo"
                  data-name={operator?.name}
                  data-alias={`${index + 1} Highlight`}
                  data-element="top-banner"
                  data-operator={operator?.name_lower}
                  data-toplist-pos={index + 1}
                ></div>
                <div class="text-container">
                  <span class="content title">
                    {#if item.title}
                      {item.title}
                    {/if}
                  </span>
                  <span class="content text">
                    {#if item.content.length}
                      {@html item.content}
                    {/if}
                  </span>
                </div>
              </div>
            </a>
          {/if}
        {/each}
      {/if}
    {/each}
  </div>
{/if}

<style>
  .logo {
    min-width: 130px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 991px) {
      min-width: 95px;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
  }
  .card-container {
    margin: 10px auto;
    gap: 14px;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 991px) {
      display: grid;
    }
  }
  .card-content {
    padding-right: 5px;
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.4px;
    text-align: left;
    overflow: hidden;
    gap: 5px;
    @media (min-width: 991px) {
      align-items: flex-start;
    }
  }

  .text-container {
    padding: 8px 0;
  }
  .title {
    display: block;
    height: 18px;
    overflow: hidden;
    padding-left: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    & > a {
      color: inherit;
    }
  }
  .text {
    height: 100%;
    display: block;
    max-height: 40px;
    overflow: hidden;
  }
</style>
