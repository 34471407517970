<script lang="ts">
  import { project, orgOperators, previewMode } from "$lib/services/store";
  import { createEventDispatcher, onMount } from "svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import { writable } from "svelte/store";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import type {
    RankingSections,
    Ratings,
  } from "@/src/types/components/operators/Ranking";
  import {
    RankingCategoriesEnum,
    RankingTitlesEnum,
  } from "$lib/common/enums/ranking";
  import RatingsCategory from "./RatingsCategory.svelte";
  import { translate } from "$lib/common/helpers/translate";
  import {
    PUBLIC_VITE_PROJECT_KEY,
    PUBLIC_RANKING_API,
  } from "$env/static/public";
  import Preview from "./Preview.svelte";

  const dispatch = createEventDispatcher();
  interface Props {
    operatorId: number;
  }

  interface Status {
    closed: boolean;
    hidden: boolean;
  }

  export let props: Props;
  export let adminView: boolean;

  const rankingCategoriesTitles = Object.keys(RankingTitlesEnum).map((key) => ({
    category: key as RankingCategoriesEnum,
    title: RankingTitlesEnum[key as RankingCategoriesEnum],
  }));

  const defaultRatingsData: Ratings = {
    operatorId: 0,
    totalOperators: 0,
    operatorName: "",
    nameLower: "",
    ratings: {
      rankingSectionsAverage: 0,
      rankingSections: {},
      rankingOthersAverage: 0,
      rankingOthers: [],
    },
    rankingList: [],
  };

  let ratingsData = writable<Ratings>(defaultRatingsData);
  let statusData = writable(<Status>{ closed: false, hidden: false });
  let activeTab = "tab-1";
  let operators: Operator[] = $orgOperators;

  const lang = $project.settings.lang;
  let loading = writable(true);

  const fetchRankings = async () => {
    try {
      const url = `${PUBLIC_RANKING_API}/${PUBLIC_VITE_PROJECT_KEY}/ratings?operator=${props.operatorId}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch rankings: ${response.statusText}`);
      }
      const data = await response.json();

      statusData.set({
        closed: data.closed,
        hidden: data.hidden,
      });

      // Convert rankingSections array to an object
      const rankingSections: RankingSections = {};
      data.ratings.rankingSections.forEach((section: any) => {
        const key = Object.keys(section)[0];
        rankingSections[key] = section[key];
      });

      const rankings: Ratings = {
        operatorId: data.operatorId,
        totalOperators: data.totalOperators,
        operatorName: data.operatorName,
        nameLower: data.nameLower,
        ratings: {
          rankingSectionsAverage: data.ratings.rankingSectionsAverage,
          rankingSections: rankingSections,
          rankingOthersAverage: data.ratings.rankingOthersAverage,
          rankingOthers: data.ratings.rankingOthers,
        },
        rankingList: data.rankingList,
      };
      ratingsData.set(rankings);
    } catch (error) {
      console.error("Error fetching rankings:", error);
    } finally {
      loading.set(false);
    }
  };

  const switchTab = (tabId: string) => {
    activeTab = tabId;
  };

  onMount(async () => {
    try {
      await fetchRankings();
    } catch (error) {
      console.error("Error on component mount:", error);
    }
  });
</script>

{#if adminView}
  <Group colspan="4-8">
    <Input
      label="Operator"
      type="select"
      bind:value={props.operatorId}
      on:change={() => dispatch("keyup")}
    >
      <option value="">~not set~</option>
      {#each operators as operator}
        <option value={operator.id}
          >{operator.name ?? "Couldn't find metadata"}</option
        >
      {/each}
    </Input>
  </Group>
{:else if $statusData.closed || $statusData.hidden}
  {#if $previewMode}
    <Preview {operators} {props} />
  {/if}
{:else}
  <div class="container rating-layout">
    <h2 class="title">{translate("Ratings", lang)}</h2>
    <div class="tab-container">
      <button
        on:click={() => switchTab("tab-1")}
        class="tab-button"
        class:active={activeTab === "tab-1"}
        >{translate("Our rating", lang)}</button
      >
      <button
        on:click={() => switchTab("tab-2")}
        class="tab-button"
        class:active={activeTab === "tab-2"}
        >{translate("Other ratings", lang)}: {$ratingsData.ratings
          .rankingOthersAverage}</button
      >
    </div>
    <div class="overall-score">
      <RatingsCategory
        {lang}
        overallScore={true}
        operatorName={$ratingsData.operatorName}
        title=""
        ratingValue={$ratingsData.ratings.rankingSectionsAverage}
        totalScore={5}
        rank={$ratingsData.ratings.rankingSections.overall?.rank || 0}
        totalOperators={$ratingsData.ratings.rankingSections.overall
          ?.totalOperators || 0}
        rankingList={$ratingsData.rankingList.find(
          (ranking) => ranking.category === RankingCategoriesEnum.OVERALL
        )?.list || []}
        medianSectionScore={$ratingsData.ratings.rankingSections[
          RankingCategoriesEnum.OVERALL
        ]?.medianSectionScore || 0}
        loading={$loading}
        operatorVisuals={$ratingsData.rankingList
          .find((ranking) => ranking.category === RankingCategoriesEnum.OVERALL)
          ?.list.find((op) => op.operatorId === $ratingsData.operatorId)
          ?.visuals || {}}
      />
    </div>
  </div>
  <div class="container ratings">
    <div class="block-content ratings-rank-container">
      {#if activeTab === "tab-1"}
        {#each rankingCategoriesTitles as { category, title }}
          {#if category !== RankingCategoriesEnum.OVERALL}
            <RatingsCategory
              {lang}
              title={translate(title, lang)}
              ratingValue={$ratingsData.ratings.rankingSections[category]
                ?.score || 0}
              totalScore={5}
              rank={$ratingsData.ratings.rankingSections[category]?.rank || 0}
              summary={$ratingsData.ratings.rankingSections[category]
                ?.summary || "No summary available"}
              rankingList={$ratingsData.rankingList.find(
                (ranking) => ranking.category === category
              )?.list || []}
              totalOperators={$ratingsData.ratings.rankingSections[category]
                ?.totalOperators || 0}
              medianSectionScore={$ratingsData.ratings.rankingSections[category]
                ?.medianSectionScore || 0}
              loading={$loading}
              operatorName={$ratingsData.operatorName}
              operatorVisuals={$ratingsData.rankingList
                .find((ranking) => ranking.category === category)
                ?.list.find((op) => op.operatorId === $ratingsData.operatorId)
                ?.visuals || {}}
            />
          {/if}
        {/each}
      {/if}

      {#if activeTab === "tab-2"}
        {#each $ratingsData.ratings.rankingOthers as other}
          {#each Object.entries(other) as [provider, details]}
            {#if details.rating_value !== null && details.rating_total !== null}
              <RatingsCategory
                title={provider}
                ratingValue={details.rating_value}
                totalScore={details.rating_total}
                totalOperators={0}
              />
            {/if}
          {/each}
        {/each}
      {/if}
    </div>
  </div>
{/if}

<style>
  .rating-layout {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "title overall-score"
      "tabs tabs";
  }
  @media (min-width: 768px) {
    .rating-layout {
      grid-template-areas:
        "title title"
        "tabs overall-score";
    }
  }
  .rating-layout > .title {
    grid-area: title;
  }

  .rating-layout > .overall-score {
    grid-area: overall-score;
  }

  .rating-layout > .tab-container {
    grid-area: tabs;
  }

  .ratings {
    font-size: 16px;
    align-items: flex-start;
    & h2 {
      margin: 0;
    }
  }
  .tab-container {
    display: flex;
    max-width: 300px;
    width: 100%;
    gap: 10px;
    align-items: center;
  }
  .tab-button {
    height: 40px;
    background-color: #f6f6f6;
    border: none;
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    &.active {
      background-color: var(--clr-pri);
      color: #fff;
    }
  }
  .tab-button:focus {
    outline: none;
  }
  .block-content {
    display: grid;
    gap: 20px;
  }

  @media (max-width: 768px) {
    .block-content {
      grid-template-columns: 1fr;
    }
  }
  @media (min-width: 769px) {
    .block-content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .ratings-rank-container {
    margin: 1rem 0;
    padding: 1rem;
    background-color: #f6f6f6;
    border-radius: 10px;
  }
</style>
