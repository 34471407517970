import { translations } from "../../data/translations"

export function getTimeDifferenceText(timestamp: string, lang: string): string {
    const t = translations as any;
    const currentTimestamp: Date = new Date();
    const givenTimestamp: Date = new Date(timestamp);

    const timeDifference: number = currentTimestamp.getTime() - givenTimestamp.getTime();
    const secondsDifference: number = Math.floor(timeDifference / 1000);
    const minutesDifference: number = Math.floor(secondsDifference / 60);
    const hoursDifference: number = Math.floor(minutesDifference / 60);
    const daysDifference: number = Math.floor(hoursDifference / 24);

    if (daysDifference >= 1) {
        return `${daysDifference} ${t['days ago'][lang]}`;
    } else if (hoursDifference >= 1) {
        return `${hoursDifference} ${t['hours ago'][lang]}`;
    } else if (minutesDifference >= 1) {
        return `${minutesDifference} ${t['minutes ago'][lang]}`;
    } else {
        return t['Just now'][lang];
    }
}