<script lang="ts">
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import { ImageFolderEnum } from "$lib/common/enums/imageFolderOptions";
  import ImageUpload from "$lib/admin/components/ImageUpload.svelte";
  import Input from "$lib/admin/components/Input.svelte";

  import Button from "$lib/main/components/Button.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import { getBucketLink } from "$lib/common/helpers/image";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import {
    fetchLiveTournaments,
    fetchRunningTournament,
  } from "$lib/services/tournaments";
  import type { Tournament } from "@/src/types/tournaments";

  interface UserStatisticsProps {
    image: string;
    tournamentId: number;
    tournamentName: string;
    title: string;
    caption: string;
  }

  export let adminView = false;
  export let props: UserStatisticsProps = {
    image: "",
    tournamentName: "",
    tournamentId: 0,
    title: "",
    caption: "",
  };

  const IMG_CONTENT_FOLDER = getBucketLink() + "media/";

  let username = "test45"; // Hardcoded for now
  let tournaments: Tournament[] = [];
  let selectedTournamentData = writable<any>(null);

  onMount(async () => {
    try {
      const tournamentData = await fetchLiveTournaments();
      tournaments = tournamentData;
      if (props.tournamentId) {
        await fetchAndSetTournamentData(props.tournamentId);
      }
    } catch (error) {
      console.error("Error fetching live tournaments:", error);
    }
  });

  async function fetchAndSetTournamentData(tournamentId: number) {
    try {
      const data = await fetchRunningTournament(tournamentId, username);
      selectedTournamentData.set(data);
    } catch (error) {
      console.error("Error fetching running tournament data:", error);
      selectedTournamentData.set(null);
    }
  }

  async function handleTournamentChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const selectedTournamentId = parseInt(target.value, 10);
    const selectedTournament = tournaments.find(
      (t) => t.tournamentId === selectedTournamentId
    );
    if (selectedTournament) {
      props.tournamentId = selectedTournament.tournamentId;
      props.tournamentName = selectedTournament.name;
      await fetchAndSetTournamentData(selectedTournamentId);
    }
  }
</script>

{#if adminView}
  <Group addClass="form-item">
    <select on:change={handleTournamentChange} bind:value={props.tournamentId}>
      <option value="" disabled selected>Select a tournament</option>
      {#each tournaments as tournament}
        <option value={tournament.tournamentId}>{tournament.name}</option>
      {/each}
    </select>
  </Group>
  <Group addClass="form-item">
    <ImageUpload
      folder={ImageFolderEnum.MEDIA}
      bind:imageUrl={props.image}
      on:keyup
    />
    <Input placeholder="Title" bind:value={props.title} on:keyup />
    <Input placeholder="Caption" bind:value={props.caption} on:keyup />
  </Group>
{:else}
  <div class="statistics container">
    <div class="grid">
      <div class="g-col-12">
        <Button addClass="text" link icon="arrow-left" iconLeft={true}
          ><Translate text="back" /></Button
        >
      </div>
      <div class="g-col-12 flex flex-column flex-start">
        <h1 class="tournament-name">{$selectedTournamentData?.name}</h1>
        <div class="game-provider-container">
          <div>
            <img
              referrerPolicy="no-referrer"
              class="game-provider-image"
              src={IMG_CONTENT_FOLDER + props.image}
              alt="tournament"
            />
          </div>
          <div>
            <div class="flex flex-column flex-start tournament-content">
              <span class="title">{props.title}</span>
              <span class="caption">{props.caption}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="g-col-12">
        <div class="flex center justify-between">
          <h2 class="tournament-stats-label">
            <Translate text="your stats" />
          </h2>
          <Button addClass="text" link icon="share" iconLeft={true}
            ><Translate text="share" /></Button
          >
        </div>
        <div class="center grid statistics">
          <div class="g-col-4 flex flex-column box title">
            <div>Score</div>
            <div>{$selectedTournamentData?.playerScoreNoPrefix || "-"}</div>
          </div>
          <div class="g-col-4 flex flex-column box title">
            <div>Rank</div>
            <div>{$selectedTournamentData?.userPosition || "-"}</div>
          </div>
          <div class="g-col-4 flex flex-column box title">
            <div>Spins</div>
            <div>
              {$selectedTournamentData?.spinsLeft || "-"} / {$selectedTournamentData?.spinsCount ||
                "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .statistics {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .center {
    align-items: center;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-start {
    align-items: flex-start;
  }
  .justify-between {
    justify-content: space-between;
  }
  .game-provider-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 10px;
    align-items: flex-start;
  }
  .game-provider-image {
    max-width: 170px;
    width: 100%;
  }
  .title {
    font-size: 16px;
    font-weight: 400;
  }
  .caption {
    font-size: 14px;
    font-weight: 400;
  }
  .tournament-name {
    font-size: 24px;
    font-weight: 400;
  }
  .tournament-stats-label {
    font-size: 20px;
    font-weight: 400;
  }
  .box {
    border-radius: 4px;
    border: 1px solid var(--bg-clr-alt-hvr);
    padding: 8px;
  }
</style>
