<script lang="ts">
  import { onMount } from "svelte";
  import { writable, derived } from "svelte/store";
  import { orgGameProviders } from "$lib/services/store";
  import { createEventDispatcher } from "svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import CountdownTimer from "./CountdownTimer.svelte";
  import { fetchTournaments } from "$lib/services/tournaments";
  import type { Tournament, PropTournament } from "@/src/types/tournaments";

  export let adminView: boolean;
  export let props: {
    tournaments: PropTournament[];
  };

  let gameProviders: { id: number; name: string }[] = $orgGameProviders;

  const dispatch = createEventDispatcher();

  const tournaments = writable<Tournament[]>([]);
  const propsTournaments = writable(props.tournaments);

  $: propsTournaments.set(props.tournaments);
  $: props.tournaments = $propsTournaments;

  const filteredAndMappedTournaments = derived(
    [tournaments, propsTournaments],
    ([$tournaments, $propsTournaments]) => {
      return $propsTournaments
        .filter(
          (item) => item.tournamentId !== null && item.gameProvider !== null
        )
        .map((item) => {
          const tournament = $tournaments.find(
            (t) => t.tournamentId === item.tournamentId
          );
          return tournament ? { ...item, ...tournament } : null;
        })
        .filter((item): item is PropTournament & Tournament => item !== null);
    }
  );

  function addTournament(): void {
    propsTournaments.update((tournaments) => [
      ...tournaments,
      { tournamentId: null, gameProvider: null },
    ]);
  }

  function removeTournament(index: number): void {
    propsTournaments.update((tournaments) =>
      tournaments.filter((_, i) => i !== index)
    );
  }

  function flipCard(index: number): void {
    propsTournaments.update((tournaments) =>
      tournaments.map((t, i) =>
        i === index ? { ...t, flipped: !t.flipped } : t
      )
    );
  }

  onMount(() => {
    fetchTournaments().then(
      (data) => tournaments.set(data),
      (error) => console.error("Failed to fetch tournaments:", error)
    );
  });
</script>

{#if adminView}
  {#each props.tournaments as item, index (index)}
    <div class="admin-tournament-wrapper">
      Tournament #{index + 1}
      <Group colspan="4-8" addClass="form-item">
        <div style="display: flex; align-items: center; font-size: 14px;">
          Event
        </div>
        <select
          bind:value={item.tournamentId}
          on:change={() => dispatch("tournamentSelected", item)}
        >
          <option value="" disabled>Select a tournament</option>
          {#each $tournaments as tournament}
            <option value={tournament.tournamentId}
              >{tournament.name} (ID: {tournament.tournamentId})</option
            >
          {/each}
        </select>
      </Group>
      <Group colspan="4-8" addClass="form-item">
        <div style="display: flex; align-items: center; font-size: 14px;">
          Game Provider
        </div>
        <select
          bind:value={item.gameProvider}
          on:change={() => dispatch("gameProviderSelected", item)}
          required
        >
          <option value="" disabled>Select a Game Provider</option>
          {#each gameProviders as gameProvider}
            <option value={gameProvider.id}
              >{gameProvider.name || "Couldn't find casino game name"}</option
            >
          {/each}
        </select>
      </Group>
      <Button
        addClass="secondary"
        icon="trash-2"
        on:click={() => removeTournament(index)}>Remove</Button
      >
    </div>
  {/each}
  <Button addClass="primary" icon="plus-square" on:click={addTournament}
    >Add tournament</Button
  >
{:else}
  <div
    class="tournaments-container"
    style={`grid-template-columns: repeat(${$filteredAndMappedTournaments.length}, 1fr);`}
  >
    {#each $filteredAndMappedTournaments as item, index (index)}
      <div class="tournament-card {item.flipped ? 'flipped' : ''}">
        <div class="front">
          <div class="visual">
            <img
              src={item.backgroundImage}
              referrerPolicy="no-referrer"
              alt={item.name}
              class="visual-bg"
            />
            <img
              src={item.logoImage}
              referrerPolicy="no-referrer"
              alt={item.name}
              class="visual-logo"
            />
          </div>
          <div class="details">
            <h2>{item.name}</h2>
            <p>
              {#if item.isLive}
                <strong>Time Left:</strong>
                <CountdownTimer endTime={item.endDate} />
              {:else}
                <strong>Time to Start:</strong>
                <CountdownTimer endTime={item.startDate} />
              {/if}
            </p>
            <p>
              <strong>Prize Pool:</strong>
              {item.prizePool || "N/A"}
            </p>
            <p><strong></strong> {item.tournamentLabel}</p>
            <button class="play">PLAY</button>
            <button class="flip" on:click={() => flipCard(index)}
              >More information</button
            >
          </div>
        </div>
        <div class="back">
          <div class="details">
            <h2>{item.name}</h2>
            <p><strong>Spins:</strong> {item.spins}</p>
            <p>{item.prizePool}</p>
            <p>{item.tournamentLabel}</p>
            <button class="play">PLAY</button>
            <button class="flip" on:click={() => flipCard(index)}>Close</button>
          </div>
        </div>
      </div>
    {/each}
  </div>
{/if}

<style>
  .admin-tournament-wrapper {
    background-color: #e8e8e8;
    padding: 20px;
    border-radius: 6px;
  }
  .tournaments-container {
    display: grid;
    gap: 20px;
  }
  .tournament-card {
    border-radius: 8px;
    overflow: hidden;
    max-width: 300px;
    margin: 20px auto;
    perspective: 1000px;
    position: relative;
    height: 500px;
    max-width: 300px;
    width: 100%;
  }
  .tournament-card .front,
  .tournament-card .back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s;
    position: absolute;
    top: 0;
    left: 0;
  }
  .tournament-card .front {
    transform: rotateY(0deg);
    background-color: #f5f5f5;
  }
  .tournament-card .back {
    transform: rotateY(180deg);
    background-color: #f9f9f9;
  }
  .tournament-card.flipped .front {
    transform: rotateY(-180deg);
  }
  .tournament-card.flipped .back {
    transform: rotateY(0deg);
  }

  .play {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #c0c0c0;
    border-radius: 4px;
    cursor: pointer;
  }

  .flip {
    margin-top: 20px;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 16px;
    background-color: transparent;
  }
  .visual-bg {
    width: 100%;
    height: 150px;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
  .visual-logo {
    object-fit: cover;
    margin: auto;
    display: block;
    height: 170px;
    padding-top: 10px;
  }
  .details {
    padding: 10px;
  }
  .details h2 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .details p {
    margin: 5px 0;
    font-size: 14px;
  }
</style>
